.filter-group button {
    color: #f8f8f8 !important;
    /*text-transform: uppercase;*/
    text-decoration: none;
    background: #FFBA08;
    padding-left: 10px;
    padding-right: 10px;
    border: 4px solid #f8f8f8 !important;
    display: inline-block;
    transition: all 0.4s ease 0s;
    font-family: "Noto Sans";
    font-size: 0.8em;
    font-weight: 700;
    width: 75;
    margin: 5px;
}
