.filter-group button {
    color: #f8f8f8 !important;
    /*text-transform: uppercase;*/
    text-decoration: none;
    background: #FFBA08;
    padding-left: 10px;
    padding-right: 10px;
    border: 4px solid #f8f8f8 !important;
    display: inline-block;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    font-family: "Noto Sans";
    font-size: 0.8em;
    font-weight: 700;
    width: 75;
    margin: 5px;
}

* {

  }
  
  .piano {
    margin:0 !important;
    padding: 0 !important;

  }

  .set ul {

    min-width: 400px;
    padding:3em 0 0 3em;
  }
  
 li {
    margin:0;
    padding:0;
    list-style:none;
    position:relative;
    float:left
  }
  
.white {
    height:8em;
    width:2.5em;
    z-index:1;
    border-left:1px solid #bbb;
    border-bottom:1px solid #bbb;
    border-radius:0 0 5px 5px;
    box-shadow:-1px 0 0 rgba(255,255,255,0.8) inset,0 0 5px #ccc inset,0 0 3px rgba(0,0,0,0.2);
    background:-webkit-linear-gradient(top,#eee 0%,#fff 100%);
    background:linear-gradient(to bottom,#eee 0%,#fff 100%)
  }
  
li.black span {
    left: 0;
  bottom: 0px;
  position: absolute;
    color: #fff;
    font-weight: bold;
    text-align: center;
  }

li.white span {
  width: 100%;
  left: 0;
  bottom: 0px;
  position: absolute;
  text-align: center;
    color: #000;
    font-weight: bold;
  }

  /*ul .white:active {
    border-top:1px solid #777;
    border-left:1px solid #999;
    border-bottom:1px solid #999;
    box-shadow:2px 0 3px rgba(0,0,0,0.1) inset,-5px 5px 20px rgba(0,0,0,0.2) inset,0 0 3px rgba(0,0,0,0.2);
    background:linear-gradient(to bottom,#fff 0%,#e9e9e9 100%)
  }*/
  
  .black {
    height:4em;
    width:1.5em;
    margin:0 0 0 -0.75em;
    z-index:2;
    border:1px solid #000;
    border-radius:0 0 3px 3px;
    box-shadow:-1px -1px 2px rgba(255,255,255,0.2) inset,0 -5px 2px 3px rgba(0,0,0,0.6) inset,0 2px 4px rgba(0,0,0,0.5);
    background:-webkit-linear-gradient(45deg,#222 0%,#555 100%);
    background:linear-gradient(45deg,#222 0%,#555 100%)
  }
  
  /*.black:active {
    box-shadow:-1px -1px 2px red inset,0 -2px 2px 3px rgba(0,0,0,0.6) inset,0 1px 2px rgba(0,0,0,0.5);
    background:linear-gradient(to right,#444 0%,#222 100%)
  }*/
  
  .key-a,.key-g,.key-d,.key-b,.key-e{
    /*margin:0 0 0 -0.75em*/
  }
 
  .key-a,.key-g,.key-d,.key-b,.key-e{
    margin:0 0 0 -0.75em
  }
 

ul li:first-child {
    border-radius:5px 0 5px 5px
  }
  
 ul li:last-child {
    border-radius:0 5px 5px 5px
  }

  .finger-pattern {
    background-color: red;
    font-size: 0.8em;
  }
.generatorFilterRoot {
    min-height: "99vh";
}
html {
  height: 100%;
  
}

body {
  background: rgb(17,107,192);
  background: -webkit-linear-gradient(top, rgba(17,107,192,1) 74%, rgba(91,151,209,1) 100%) fixed;
  background: linear-gradient(180deg, rgba(17,107,192,1) 74%, rgba(91,151,209,1) 100%) fixed;
  color: #f8f8f8;
  font-family: "Noto Sans" !important;
  height: 100%;
}

#root {
 
  height: 100%;
}

.navbar {
  background-color: #116bc0 !important;
  color: #f8f8f8 !important;
}
.fill
{
  min-height: 100vh;
}

@media only screen 
  and (min-device-width: 800px) {
.center {
  margin: 0;
  /*position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);*/
  width: 100%;
  max-width: 100% !important;
  padding: 10px;
  text-align: center;
  overflow: visible;
  display: block;
}

.filter-group {
  width: 50%;
  margin: auto;
}

}
@media only screen 
  and (max-device-width: 800px) {


  .center {
    margin: auto;
    width: 99%;
    max-width: 99% !important;
    padding: 10px;
    text-align: center;

  overflow: visible;
  }

  .filter-group {
    width: 100%;
    margin: auto;
  }
}
.center-text {
  text-align: center;
}

.logo {
  font-size: 1.5em;
  font-family: "Raleway";
  font-weight: 300;
  color: #fff;
  letter-spacing: -1px; 
  line-height: 0.8em;
}

.logo b {
  font-weight: 800;
}

.title {
    color: "#fff" !important;
    font-size: 4em;
    font-weight: 500;
}

#generate-button {
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #ffffff;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Lato";
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
#generate-button:hover {
  color: #000000;
  background-color: #ffffff;
}
@media all and (max-width: 30em) {
   #generate-button {
    display: block;
    margin: 0.4em auto;
  }
}

.example_c:hover {
    color: #ffffff !important;
    background-color: #e2ab1c;
    background-color: #e2ab1c !important;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    
}

.example_c {
    color: #f8f8f8 !important;
    /*text-transform: uppercase;*/
    text-decoration: none;
    background: #FFBA08 !important;
    padding: 20px;
    margin: 10px;
    border: 4px solid #f8f8f8 !important;
    display: inline-block;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    font-family: "Noto Sans";
    font-size: 1.8em;
    font-weight: 700 !important;
}

.example_d:hover {
  color: #ffffff !important;
  background-color: #e2ab1c;
  background-color: #e2ab1c !important;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  
}

.example_d {
  color: #f8f8f8 !important;
  /*text-transform: uppercase;*/
  text-decoration: none;
  background: #FFBA08 !important;
  padding-left: 20px;
  padding-right: 10px;
  border: 4px solid #f8f8f8 !important;
  display: inline-block;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  font-family: "Noto Sans";
  font-size: 1.2em;
  font-weight: bold !important;
  width: 150px;
}

.settings {
  color: #000;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 10px;
  text-align: left;
  line-height: 1.5em;
}

.settings-title {
  line-height: 1.7em;
  font-size: 1.7em;
  margin-bottom: 10px;
}

.settings-filter {
  font-size: 1em;
  letter-spacing: 0.5;
}

.fa {
  
  color: #fff !important;
}

.fa:hover {
  opacity: 0.9;
  text-decoration: none;
  color: #fff !important;
}

.next-buttons {
  width: 100%;
  display: block;
}

.small-text {
  font-size: 0.6em;
}

.dialog {
  display: none;
}
.nav-link {
    color: #fff !important;
    -webkit-font-feature-settings: normal !important;
            font-feature-settings: normal !important;
    font-variant: normal !important;
    text-transform: lowercase !important;
}

.headerRoot {
    background-color: "blue";
    padding: 0;
    margin: 0;
}

  .headerToolbar {
    min-height: 128;
    align-items: 'flex-start';
    color: "#404040";

  }

  .headerTitle {
    flex-grow: 1;
    font-size: "1em";
    color: "black";
    font-family: "Rubik";
  }

  .headerToolbar {
      background-color: "inherit"
  }
  /*palette {
    default: {
      main: "#fff"
    },
    primary: {
      main: "#fff"
    },
    colorDefault: "#fff",
    colorPrimary: "#fff",
    colorSecondary: "#fff"
  }*/
  /*offset: theme.mixins.toolbar,*/
  .headerItem {
    -webkit-font-feature-settings: ;
            font-feature-settings: ;
    font-variant: "none";
    text-transform: "none";
    -webkit-text-decoration: "none";
            text-decoration: "none";
    text-transform: capitalize !important;
    color: "rgba(0,0,0,.5)";
    font-size: "0.8em";
    font-family: "Roboto";
  }

  .headerItem :hover {
    color: #ffffff !important;
    background-color: "inherit";
    background-color: "inherit" !important;
  }

  .headerItemCTA {
    -webkit-font-feature-settings: ;
            font-feature-settings: ;
    font-variant: "none";
    text-transform: "none";
    -webkit-text-decoration: "none";
            text-decoration: "none";
    text-transform: capitalize !important;
    color: rgb(248, 248, 248);
    font-size: "0.8em";
    font-family: "Roboto";
    border: 1px solid #fff !important;
    background: #e2ab1c   !important;
  }
html {
  height: 100%;
  
}

body {
  background: rgb(17,107,192);
  background: -webkit-linear-gradient(top, rgba(17,107,192,1) 74%, rgba(91,151,209,1) 100%) fixed;
  background: linear-gradient(180deg, rgba(17,107,192,1) 74%, rgba(91,151,209,1) 100%) fixed;
  color: #f8f8f8;
  font-family: "Noto Sans" !important;
  height: 100%;
}

#root {
 
  height: 100%;
}

.navbar {
  background-color: #116bc0 !important;
  color: #f8f8f8 !important;
}
.fill
{
  min-height: 100vh;
}

@media only screen 
  and (min-device-width: 800px) {
.center {
  margin: 0;
  /*position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);*/
  width: 100%;
  max-width: 100% !important;
  padding: 10px;
  text-align: center;
  overflow: visible;
  display: block;
}

.filter-group {
  width: 50%;
  margin: auto;
}

}
@media only screen 
  and (max-device-width: 800px) {


  .center {
    margin: auto;
    width: 99%;
    max-width: 99% !important;
    padding: 10px;
    text-align: center;

  overflow: visible;
  }

  .filter-group {
    width: 100%;
    margin: auto;
  }
}
.center-text {
  text-align: center;
}

.logo {
  font-size: 1.5em;
  font-family: "Raleway";
  font-weight: 300;
  color: #fff;
  letter-spacing: -1px; 
  line-height: 0.8em;
}

.logo b {
  font-weight: 800;
}

.title {
    color: "#fff" !important;
    font-size: 4em;
    font-weight: 500;
}

#generate-button {
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #ffffff;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Lato";
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
#generate-button:hover {
  color: #000000;
  background-color: #ffffff;
}
@media all and (max-width: 30em) {
   #generate-button {
    display: block;
    margin: 0.4em auto;
  }
}

.example_c:hover {
    color: #ffffff !important;
    background-color: #e2ab1c;
    background-color: #e2ab1c !important;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    
}

.example_c {
    color: #f8f8f8 !important;
    /*text-transform: uppercase;*/
    text-decoration: none;
    background: #FFBA08 !important;
    padding: 20px;
    margin: 10px;
    border: 4px solid #f8f8f8 !important;
    display: inline-block;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    font-family: "Noto Sans";
    font-size: 1.8em;
    font-weight: 700 !important;
    text-transform: "none";
    -webkit-text-decoration: "none";
            text-decoration: "none";
    text-transform: capitalize !important;
}

.example_d:hover {
  color: #ffffff !important;
  background-color: #e2ab1c;
  background-color: #e2ab1c !important;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  
}

.example_d {
  color: #f8f8f8 !important;
  /*text-transform: uppercase;*/
  text-decoration: none;
  background: #FFBA08 !important;
  padding-left: 20px;
  padding-right: 10px;
  border: 4px solid #f8f8f8 !important;
  display: inline-block;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  font-family: "Noto Sans";
  font-size: 1.2em;
  font-weight: bold !important;
  width: 150px;
  text-transform: "none";
    -webkit-text-decoration: "none";
            text-decoration: "none";
    text-transform: capitalize !important;
}

.settings {
  color: #000;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 10px;
  text-align: left;
  line-height: 1.5em;
}

.settings-title {
  line-height: 1.7em;
  font-size: 1.7em;
  margin-bottom: 10px;
}

.settings-filter {
  font-size: 1em;
  letter-spacing: 0.5;
}

.fa {
  
  color: #fff !important;
}

.fa:hover {
  opacity: 0.9;
  text-decoration: none;
  color: #fff !important;
}

.next-buttons {
  width: 100%;
  display: block;
}

.small-text {
  font-size: 0.6em;
}

.dialog {
  display: none;
}

.cta-text {
  font-size: 8em;
  font-family: "Raleway" !important;
}

@media only screen 
  and (max-device-width: 800px) {
  .cta-text {
    font-size: 4.4em !important;
    font-family: "Raleway" !important;
  }
}

.cta-sub-text {
  font-size: 5em;
  font-family: "Raleway" !important;
}

@media only screen 
  and (max-device-width: 800px) {
  .cta-sub-text {
    font-size: 2.2em !important;
    font-family: "Raleway" !important;
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  
}

a {
  text-decoration: none !important;
}

a:hover {
  color: inherit;
}
.scaleLibraryRoot {
    min-height: 99vh;
    margin-top: 1em;
    margin-bottom: 20px;
    flex-grow: 1;
}
