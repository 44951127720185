.nav-link {
    color: #fff !important;
    font-variant: normal !important;
    text-transform: lowercase !important;
}

.headerRoot {
    background-color: "blue";
    padding: 0;
    margin: 0;
}

  .headerToolbar {
    min-height: 128;
    align-items: 'flex-start';
    color: "#404040";

  }

  .headerTitle {
    flex-grow: 1;
    font-size: "1em";
    color: "black";
    font-family: "Rubik";
  }

  .headerToolbar {
      background-color: "inherit"
  }
  /*palette {
    default: {
      main: "#fff"
    },
    primary: {
      main: "#fff"
    },
    colorDefault: "#fff",
    colorPrimary: "#fff",
    colorSecondary: "#fff"
  }*/
  /*offset: theme.mixins.toolbar,*/
  .headerItem {
    font-variant: "none";
    text-transform: "none";
    text-decoration: "none";
    text-transform: capitalize !important;
    color: "rgba(0,0,0,.5)";
    font-size: "0.8em";
    font-family: "Roboto";
  }

  .headerItem :hover {
    color: #ffffff !important;
    background-color: "inherit";
    background-color: "inherit" !important;
  }

  .headerItemCTA {
    font-variant: "none";
    text-transform: "none";
    text-decoration: "none";
    text-transform: capitalize !important;
    color: rgb(248, 248, 248);
    font-size: "0.8em";
    font-family: "Roboto";
    border: 1px solid #fff !important;
    background: #e2ab1c   !important;
  }