* {

  }
  
  .piano {
    margin:0 !important;
    padding: 0 !important;

  }

  .set ul {

    min-width: 400px;
    padding:3em 0 0 3em;
  }
  
 li {
    margin:0;
    padding:0;
    list-style:none;
    position:relative;
    float:left
  }
  
.white {
    height:8em;
    width:2.5em;
    z-index:1;
    border-left:1px solid #bbb;
    border-bottom:1px solid #bbb;
    border-radius:0 0 5px 5px;
    box-shadow:-1px 0 0 rgba(255,255,255,0.8) inset,0 0 5px #ccc inset,0 0 3px rgba(0,0,0,0.2);
    background:linear-gradient(to bottom,#eee 0%,#fff 100%)
  }
  
li.black span {
    left: 0;
  bottom: 0px;
  position: absolute;
    color: #fff;
    font-weight: bold;
    text-align: center;
  }

li.white span {
  width: 100%;
  left: 0;
  bottom: 0px;
  position: absolute;
  text-align: center;
    color: #000;
    font-weight: bold;
  }

  /*ul .white:active {
    border-top:1px solid #777;
    border-left:1px solid #999;
    border-bottom:1px solid #999;
    box-shadow:2px 0 3px rgba(0,0,0,0.1) inset,-5px 5px 20px rgba(0,0,0,0.2) inset,0 0 3px rgba(0,0,0,0.2);
    background:linear-gradient(to bottom,#fff 0%,#e9e9e9 100%)
  }*/
  
  .black {
    height:4em;
    width:1.5em;
    margin:0 0 0 -0.75em;
    z-index:2;
    border:1px solid #000;
    border-radius:0 0 3px 3px;
    box-shadow:-1px -1px 2px rgba(255,255,255,0.2) inset,0 -5px 2px 3px rgba(0,0,0,0.6) inset,0 2px 4px rgba(0,0,0,0.5);
    background:linear-gradient(45deg,#222 0%,#555 100%)
  }
  
  /*.black:active {
    box-shadow:-1px -1px 2px red inset,0 -2px 2px 3px rgba(0,0,0,0.6) inset,0 1px 2px rgba(0,0,0,0.5);
    background:linear-gradient(to right,#444 0%,#222 100%)
  }*/
  
  .key-a,.key-g,.key-d,.key-b,.key-e{
    /*margin:0 0 0 -0.75em*/
  }
 
  .key-a,.key-g,.key-d,.key-b,.key-e{
    margin:0 0 0 -0.75em
  }
 

ul li:first-child {
    border-radius:5px 0 5px 5px
  }
  
 ul li:last-child {
    border-radius:0 5px 5px 5px
  }

  .finger-pattern {
    background-color: red;
    font-size: 0.8em;
  }