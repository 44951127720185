html {
  height: 100%;
  
}

body {
  background: rgb(17,107,192);
  background: linear-gradient(180deg, rgba(17,107,192,1) 74%, rgba(91,151,209,1) 100%) fixed;
  color: #f8f8f8;
  font-family: "Noto Sans" !important;
  height: 100%;
}

#root {
 
  height: 100%;
}

.navbar {
  background-color: #116bc0 !important;
  color: #f8f8f8 !important;
}
.fill
{
  min-height: 100vh;
}

@media only screen 
  and (min-device-width: 800px) {
.center {
  margin: 0;
  /*position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);*/
  width: 100%;
  max-width: 100% !important;
  padding: 10px;
  text-align: center;
  overflow: visible;
  display: block;
}

.filter-group {
  width: 50%;
  margin: auto;
}

}
@media only screen 
  and (max-device-width: 800px) {


  .center {
    margin: auto;
    width: 99%;
    max-width: 99% !important;
    padding: 10px;
    text-align: center;

  overflow: visible;
  }

  .filter-group {
    width: 100%;
    margin: auto;
  }
}
.center-text {
  text-align: center;
}

.logo {
  font-size: 1.5em;
  font-family: "Raleway";
  font-weight: 300;
  color: #fff;
  letter-spacing: -1px; 
  line-height: 0.8em;
}

.logo b {
  font-weight: 800;
}

.title {
    color: "#fff" !important;
    font-size: 4em;
    font-weight: 500;
}

#generate-button {
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #ffffff;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Lato";
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;
}
#generate-button:hover {
  color: #000000;
  background-color: #ffffff;
}
@media all and (max-width: 30em) {
   #generate-button {
    display: block;
    margin: 0.4em auto;
  }
}

.example_c:hover {
    color: #ffffff !important;
    background-color: #e2ab1c;
    background-color: #e2ab1c !important;
    transition: all 0.4s ease 0s;
    
}

.example_c {
    color: #f8f8f8 !important;
    /*text-transform: uppercase;*/
    text-decoration: none;
    background: #FFBA08 !important;
    padding: 20px;
    margin: 10px;
    border: 4px solid #f8f8f8 !important;
    display: inline-block;
    transition: all 0.4s ease 0s;
    font-family: "Noto Sans";
    font-size: 1.8em;
    font-weight: 700 !important;
    text-transform: "none";
    text-decoration: "none";
    text-transform: capitalize !important;
}

.example_d:hover {
  color: #ffffff !important;
  background-color: #e2ab1c;
  background-color: #e2ab1c !important;
  transition: all 0.4s ease 0s;
  
}

.example_d {
  color: #f8f8f8 !important;
  /*text-transform: uppercase;*/
  text-decoration: none;
  background: #FFBA08 !important;
  padding-left: 20px;
  padding-right: 10px;
  border: 4px solid #f8f8f8 !important;
  display: inline-block;
  transition: all 0.4s ease 0s;
  font-family: "Noto Sans";
  font-size: 1.2em;
  font-weight: bold !important;
  width: 150px;
  text-transform: "none";
    text-decoration: "none";
    text-transform: capitalize !important;
}

.settings {
  color: #000;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 10px;
  text-align: left;
  line-height: 1.5em;
}

.settings-title {
  line-height: 1.7em;
  font-size: 1.7em;
  margin-bottom: 10px;
}

.settings-filter {
  font-size: 1em;
  letter-spacing: 0.5;
}

.fa {
  
  color: #fff !important;
}

.fa:hover {
  opacity: 0.9;
  text-decoration: none;
  color: #fff !important;
}

.next-buttons {
  width: 100%;
  display: block;
}

.small-text {
  font-size: 0.6em;
}

.dialog {
  display: none;
}

.cta-text {
  font-size: 8em;
  font-family: "Raleway" !important;
}

@media only screen 
  and (max-device-width: 800px) {
  .cta-text {
    font-size: 4.4em !important;
    font-family: "Raleway" !important;
  }
}

.cta-sub-text {
  font-size: 5em;
  font-family: "Raleway" !important;
}

@media only screen 
  and (max-device-width: 800px) {
  .cta-sub-text {
    font-size: 2.2em !important;
    font-family: "Raleway" !important;
  }
}